import { Box, Grid, Link, Stack, Typography } from "@mui/material";
import { MobileDatePicker, PickersDay } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AidemLaicosImage from "../../assets/images/aidem_laicos.jpg";
import { typedStoreHooks } from "../../store";
import { RoomCard } from "./RoomCard";
import { CustomPickerDay } from "../SharedComponents";

const ReservationComponent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(["translation", "common"]);

  const navigateToContact = useCallback(() => {
    navigate("/home#contact");
  }, [navigate]);

  const { selectedDate: dateTime, reservationTimes } =
    typedStoreHooks.useStoreState(({ reservationState }) => reservationState);

  const reservationKey = useMemo(() => {
    const day = dateTime.date().toString().padStart(2, "0"); // Get the day (1-31)
    const month = (dateTime.month() + 1).toString().padStart(2, "0"); // Get the month (0-11) and add 1
    const year = dateTime.year(); // Get the year (e.g., 2023)

    return `${day}-${month}-${year}`;
  }, [dateTime]);

  const updateFields = typedStoreHooks.useStoreActions(
    (actions) => actions.updateFields
  );

  return (
    <Box pt={{ md: 8, xs: 4 }} width={{ lg: 1200, md: 900, xs: 330 }}>
      <Stack>
        <Typography
          variant="body2"
          textAlign="center"
          pb={4}
          color="text.secondary"
        >
          {t("reservation.openingSub", { ns: "common" })}
        </Typography>
        <Stack
          direction={{ md: "row", xs: "column" }}
          justifyContent="space-between"
          alignItems="center"
          mb={3}
        >
          <Stack direction="row" display={{ xs: "flex", md: "none" }}>
            <Typography mr={1} mb={4} textAlign="center">
              {t("reservation.specificNeeds.sentence", { ns: "common" })}
              <Link
                component="button"
                onClick={navigateToContact}
                sx={{ paddingBottom: "3px" }}
              >
                {t("reservation.specificNeeds.suffix", { ns: "common" })}
              </Link>
            </Typography>
          </Stack>

          <MobileDatePicker
            label={t("reservation.selectedDate", { ns: "common" })}
            sx={{ width: "200px" }}
            value={dayjs(dateTime)}
            onChange={(newValue) => {
              if (newValue) {
                updateFields({ selectedDate: newValue });
              }
            }}
            maxDate={dayjs().add(60, "day")}
            disablePast
            dayOfWeekFormatter={(_day: string) => _day}
            slotProps={{
              textField: {
                variant: "standard",
              },
            }}
            slots={{
              day: (props) => (
                <CustomPickerDay
                  {...props}
                  reservationTimesData={reservationTimes?.data}
                />
              ),
            }}
          />
          <Stack direction="row" display={{ xs: "none", md: "flex" }}>
            <Typography mr={1} textAlign="center">
              {t("reservation.specificNeeds.sentence", { ns: "common" })}
              <Link
                component="button"
                onClick={navigateToContact}
                sx={{ paddingBottom: "3px" }}
              >
                {t("reservation.specificNeeds.suffix", { ns: "common" })}
              </Link>
            </Typography>
          </Stack>
        </Stack>
        <Grid container spacing={4} justifyContent="space-between">
          <Grid item xs={12} md={6} lg={4}>
            <RoomCard
              escapeRoomId={0}
              imageProps={{ image: AidemLaicosImage }}
              // secondaryImageProps={{ image: AidemLaicosImage }}
              title={t("experiment23.title", { ns: "common" })}
              shortDescription={t("experiment23.description", { ns: "common" })}
              duration={60}
              difficulty={4}
              pricePlayerMap={{
                2: 65,
                3: 60,
                4: 55,
                5: 55,
                6: 50,
                7: 45,
                8: 45,
                9: 40,
                10: 40,
              }}
              reservationTimes={
                reservationTimes.data
                  ? reservationTimes.data[reservationKey]
                  : []
              }
            />
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
};

export default ReservationComponent;
